import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { IoPricetag, IoTime } from "react-icons/io5";
import Seo from "../components/seo";

const Delivery = () => {
    return (
        <Layout>
                                    <Seo
                title="Livraison gratuite pour toutes les commandes - BIKESAFE"
                description=">Chez bikesafe.fr, nous nous efforçons au mieux de livrer votre commande dans les plus brefs délais. Cependant, si l'un de nos articles est en rupture de stock, l'un de nos conseillers du service clientèle vous contactera par e-mail..."
            />
        <Container>
            <Row>
                <Col sm="12">
                    <h1 className="mt-5 mb-5">
                        Livraison gratuite pour toutes les commandes
                    </h1>
                    <p className="flow-text">Chez bikesafe.fr, nous nous efforçons au mieux de livrer votre commande dans les plus brefs délais. Cependant, si l'un de nos articles est en rupture de stock, l'un de nos conseillers du service clientèle vous contactera par e-mail ou par téléphone afin de vous tenir informé de la progression de votre commande.</p>
                    <h2 className="mt-5 mb-5"><IoPricetag/> Combien coûte la livraison ?</h2>
                    <p className="flow-text">La livraison est <strong>gratuite</strong> mais pour certaines régions françaises, des frais de transport seront ajoutés.</p>
                    <h2 className="mt-5 mb-5"><IoTime/> Quel est le délai de livraison ?</h2>
                    <p className="flow-text">Les délais de livraison varient en fonction du produit. Une estimation des délais de livraison est indiquée dans la mesure du possible pour chaque produit. Toutefois, les délais exacts ne peuvent être confirmés.</p>
                </Col>
            </Row>
        </Container>
        </Layout>
    )
}

export default Delivery;